const Contect = () => {
  return (
    <div className="w-[100vw]">
      <iframe
        className="w-[100%]"
        title="mukesh" src="https://docs.google.com/forms/d/e/1FAIpQLSfGKloXkd3YEgJ6RVJj6ZHnACGMP582gt6N5Iz3qfhql6g_sQ/viewform?embedded=true"
        width="640"
        height="983"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
};
export default Contect;
