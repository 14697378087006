import { RiTimeZoneFill } from "react-icons/ri";
const Course = () => {
  return (
    <div className="flex justify-center items-center h-screen text-[25px] text-gray-600 mt-[-100px]">
      <RiTimeZoneFill />
    <p className="ml-4">Coming soon...</p>
    
    </div>
    );
}
export default Course;